import mixpanel from 'mixpanel-browser';
import React, {useEffect, useState} from 'react';

//HereIntegrationSave
export interface IntegrationSceneProps<SaveT> {
  prefixId?: string;
  switchScene: (index: number, data?: Partial<SaveT>) => void;
  switchSceneWithoutSave: (index: number) => void;
  save: (data: Partial<SaveT>) => void;
  loadData?: SaveT;
}

interface SceneProps {
  id: string;
  isActive: boolean;
  children: React.ReactNode;
}

const IntegrationScene: React.FC<SceneProps> = ({id, isActive, children}) => {
  const [element] = useState(
    React.Children.map(children, (child) => {
      if (React.isValidElement<IntegrationSceneProps<unknown>>(child)) {
        return React.cloneElement(child, {prefixId: id});
      }
      console.error('Not a valid scene component!');
      return child;
    }),
  );

  useEffect(() => {
    if (isActive === true) {
      console.log(`STATISTICS: Switched to scene "${id}"`);
      mixpanel.track('Scene launch', {
        id: id,
      });
    }
  }, [id, isActive]);

  if (!isActive) return null;
  return <>{element}</>;
};

export default IntegrationScene;
